import { fetchLawyerList, deleteLawyer, createLawyer } from './fetches';
import { IconEdit, IconX, IconPlus } from '@tabler/icons-react';
// import path from 'path';

import {
  Table,
  Anchor,
  Button,
  Modal,
  Center,
  Stack,
  ScrollArea,
  Input,
  Group,
  TextInput,
} from '@mantine/core';
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { LawyerHeader } from '../../interfaces/types';
import { useTranslation } from 'react-i18next';

export default function EditLawyer() {
  const [lawyerList, setLawyerList] = useState<LawyerHeader[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLawyerName, setNewLawyerName] = useState('');

  const getLawyerList = useCallback(async () => {
    console.log('Fetching data');
    const accessToken = await getAccessTokenSilently();
    console.log('Token received');
    const fetchedData = await fetchLawyerList(accessToken);
    const modifiedData = fetchedData?.map((item: LawyerHeader) => {
      return {
        ...item,
        last_edited: item.last_edited ? new Date(item.last_edited) : new Date(),
      };
    });
    setLawyerList(modifiedData ? modifiedData : []);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getLawyerList();
  }, []);

  const handleCreateLawyer = async () => {
    const accessToken = await getAccessTokenSilently();
    const response = await createLawyer(accessToken, { LawyerName: newLawyerName });
    navigate(`/editlawyer/${newLawyerName}`);
  };

  const filteredLawyerList = lawyerList.filter((lawyer) =>
    lawyer.LawyerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Stack align="center">
      <Group justify="space-between" align="center" w="100%">
        <Input
          placeholder={t('searchLawyer')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '300px' }}
        />
        <Button
          variant="light"
          leftSection={<IconPlus size={16} />}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {t('addLawyer')}
        </Button>
      </Group>
      <Table.ScrollContainer minWidth={600}>
        <Table verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t('lawyerName')}</Table.Th>
              <Table.Th>{t('dateEdited')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {filteredLawyerList.map((row) => (
              <LawyerRow key={row.LawyerName} row={row} onDelete={getLawyerList} />
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      {/* </ScrollArea> */}
      <Modal opened={isModalOpen} onClose={() => setIsModalOpen(false)} title={t('addLawyer')}>
        <Center>
          <Group gap="md" w="50%" miw="300px">
            <TextInput
              label={t('name')}
              name="LawyerName"
              value={newLawyerName}
              onChange={(e) => {
                setNewLawyerName(e.target.value);
              }}
              required
            />
            <Group style={{ justifyContent: 'flex-end' }} mt="lg">
              <Button type="button" mt="md" onClick={handleCreateLawyer}>
                Save
              </Button>
            </Group>
          </Group>
        </Center>
      </Modal>
    </Stack>
  );
}

function LawyerRow({ row, onDelete }: { row: LawyerHeader; onDelete: () => void }) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const lastEditDate = row.last_edited ? new Date(row.last_edited) : new Date();
  const formattedDate = lastEditDate.toISOString().split('T')[0];

  const handleEditClick = () => {
    navigate(`/editlawyer/${row.LawyerName}`);
  };

  const handleDeleteClick = () => {
    async function deleteProposalData() {
      const accessToken = await getAccessTokenSilently();
      await deleteLawyer(accessToken, row.LawyerName);
      onDelete(); // Call the callback function after deletion
    }
    deleteProposalData();
  };

  return (
    <Table.Tr key={row.LawyerName}>
      <Table.Td>
        <Anchor component="button" fz="sm" onClick={handleEditClick}>
          {row.LawyerName}
        </Anchor>
      </Table.Td>
      <Table.Td>{formattedDate}</Table.Td>
      <Table.Td style={{ textAlign: 'right' }}>
        <Button
          variant="light"
          color="green"
          mt="lg"
          style={{ width: 'fit-content', marginRight: '8px' }}
          leftSection={<IconEdit size={16} />}
          onClick={handleEditClick}
        >
          {t('edit')}
        </Button>

        <Button
          variant="light"
          color="red"
          mt="lg"
          style={{ width: 'fit-content' }}
          leftSection={<IconX size={16} />}
          onClick={() => setIsModalOpen(true)}
        >
          {t('delete')}
        </Button>
        <Modal
          opened={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={t('confirmDeletion')}
        >
          <div>{t('confirmDeleteProposal')}</div>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', gap: '12px' }}
          >
            <Button color="gray" onClick={() => setIsModalOpen(false)}>
              {t('cancel')}
            </Button>
            <Button color="red" onClick={handleDeleteClick}>
              {t('confirm')}
            </Button>
          </div>
        </Modal>
      </Table.Td>
    </Table.Tr>
  );
}
