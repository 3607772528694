export const OTHERDATAACTIONS = {
  INCREMENT_CURRENT_LAWYER_COMPLETIONS: 'INCREMENT_CURRENT_LAWYER_COMPLETIONS',
};

export function otherDataReducer(state: typeof initialOtherState, action: any) {
  switch (action.type) {
    case OTHERDATAACTIONS.INCREMENT_CURRENT_LAWYER_COMPLETIONS:
      return {
        ...state,
        currentLawyerCompletions: state.currentLawyerCompletions + 1,
      };
    default:
      return {
        ...state,
        [action.field]: action.value,
      };
  }
}

export const initialOtherState = {
  allLawyers: {} as { [key: string]: string },
  expertise: [
    'Any',
    'Energy',
    'Intellectual Property',
    'Food Law',
    'Italian Desk',
    'Life Sciences',
    'Banking & Finance',
    'Environment & Climate Changes',
    // Add more expertise areas as needed
  ] as string[],
  seniority: ['Junior', 'Mid-level', 'Senior'] as string[],
  documentLanguage: 'pl',
  neededLawyerCompletions: 1,
  currentLawyerCompletions: 0,
};
