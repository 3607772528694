import { authPost, authGet } from '../auth/authFetches';
import { FileMetadata, PresignedURL, GetPresignedURLsResponse } from '@/interfaces/types';

export async function removeFile(accessToken: string, filename: string) {
    const path = `/files/removeFile`;
    return await authPost(accessToken, path, { filename });
}

export async function getFilelist(accessToken: string) {
    const path = '/files/get-filelist';
    return await authGet(accessToken, path)
}

export async function getPresignedURLs(
    accessToken: string,
    files: FileMetadata[],
    bucketName: string
  ): Promise<GetPresignedURLsResponse> {
    const path = '/files/generate-presigned-urls';
    const params = { files, bucketName };
    return await authPost(accessToken, path, params) as GetPresignedURLsResponse;
  }
  

export async function notifyFileUpload(accessToken: string, filename: string, functionName: string) {
  const path = '/files/notify-upload';
  const params = { filename, functionName };
  return await authPost(accessToken, path, params);
}