import { authGet, authPost } from '../auth/authFetches';
import { Proposal } from '../interfaces/types';

export async function fetchProposalList(accessToken: string) {
  const path = `/proposalsData/listProposals`;
  return await authGet(accessToken, path);
}

export async function fetchProposal(accessToken: string, id: string) {
  const path = `/proposalsData/fetchProposal`;
  return await authPost(accessToken, path, { id });
}

export async function saveProposal(accessToken: string, proposal: Proposal) {
  const path = `/proposalsData/saveProposal`;
  return await authPost(accessToken, path, proposal);
}

export async function deleteProposal(accessToken: string, id: string) {
  const path = `/proposalsData/deleteProposal`;
  console.log('Deleting proposal with id:', id);
  return await authPost(accessToken, path, { id });
}

export async function generateWordDoc(
  accessToken: string,
  body: any,
  setDocumentLoading: (loading: boolean) => void,
  signal: AbortSignal
) {
  setDocumentLoading(true);

  const path = '/proposalsData/generateWordDoc';
  console.log('Generating Word Doc');
  const domain = import.meta.env.VITE_API_URL || '';
  const uri = domain + path;
  try {
    const res = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
      signal: signal,
    });

    const blob = await res.blob();

    const contentDisposition = res.headers.get('Content-Disposition');
    let filename = 'proposal.docx'; // Default filename

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+?)"/);
      if (match && match[1]) {
        filename = match[1];
      }
    }

    const url = window.URL.createObjectURL(blob); // Create a URL for the Blob
    const a = document.createElement('a'); // Create a temporary <a> element
    a.href = url;
    a.download = filename; // Set the filename

    document.body.appendChild(a); // Append the <a> element to the body
    a.click(); // Programmatically click the <a> element to trigger download
    a.remove(); // Remove the <a> element from the document
    window.URL.revokeObjectURL(url); // Clean up the URL object
    setDocumentLoading(false);
  } catch (err: any) {
    if (err.name === 'AbortError') {
      console.log('Fetch aborted');
      setDocumentLoading(false);
      return;
    }
    setDocumentLoading(false);
    console.error(
      `POST request to uri ${uri} with body:\n${JSON.stringify(body, undefined, 4)} failed`
    );
    console.error(err);
    return;
  }
}
export async function generateExperiencesOffer(accessToken: string, query: string) {
  const path = '/proposalsData/generateExperiencesOffer';
  console.log('Generating Experiences for Offer');
  return await authPost(accessToken, path, { query });
}

export async function generateLawyersOffer(accessToken: string, query: string) {
  const path = '/proposalsData/generateLawyersOffer';
  console.log('Generating Lawyers for Offer');
  return await authPost(accessToken, path, { query });
}

export async function getLawyerList(accessToken: string) {
  const path = '/proposalsData/getLawyerList';
  console.log('Getting list of lawyers');
  return await authGet(accessToken, path);
}

export async function fetchExpertise(accessToken: string) {
  const path = `/proposalsData/getExpertise`;
  return await authGet(accessToken, path);
}
