import { useState, useEffect } from 'react';
import {
  TextInput,
  Button,
  Stack,
  Paper,
  Group,
  Title,
  Center,
  MultiSelect,
  Select,
  Textarea,
  Box,
  FileInput,
  Autocomplete,
  Tooltip,
  Text,
  Loader,
} from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { updateLawyer } from '../ViewLawyers/fetches';
import { fetchLawyer, fetchLawyerImage, updateImage, resummariseLawyer, deleteLawyerImage } from './fetches';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { IconCheck, IconInfoCircle } from '@tabler/icons-react';
import { set } from 'lodash';
import BasicTable from '../../components/Tables/BasicTable';
import { styles } from '../EditProposal/styles';
import { fetchExpertise } from '@/services/proposalsRoutes';

interface Lawyer {
  LawyerName: string;
  image?: File | null;
  Bio: string;
  Docs: any[];
  Expertise: string[];
  Position: string;
  PositionPol: string;
  Seniority: string | null;
  email?: string;
  phone?: string;
  last_edited?: Date;
  location?: string;
}

const EditLawyerInfo: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [lawyerData, setLawyerData] = useState<Lawyer>({
    LawyerName: '',
    Bio: '',
    Docs: [],
    Expertise: [],
    Position: '',
    PositionPol: '',
    Seniority: null,
    email: '',
    phone: '',
    last_edited: undefined,
    location: '',
  });

  const [image, setImage] = useState<File | null>(null);
  const [expertiseList, setExpertiseList] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getLawyerInfo = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (id) {
          const response = await fetchLawyer(accessToken, id);
          const image = await fetchLawyerImage(accessToken, id);
          if (response) {
            setLawyerData(response);
            // @ts-ignore
            setImage(image);
          }
        }
      } catch (error) {
        console.error('Error fetching lawyer data:', error);
        alert('Failed to fetch lawyer data.');
      } finally {
        setLoading(false);
      }
    };
    getLawyerInfo();
  }, [getAccessTokenSilently, id]);

  useEffect(() => {
    async function getExpertise() {
      const accessToken = await getAccessTokenSilently();
      const response = await fetchExpertise(accessToken);
      console.log('Expertise fetch: ', response);
      setExpertiseList(response);
    }
    getExpertise();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setLawyerData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleExpertiseChange = (values: string[]) => {
    setLawyerData((prevData) => ({ ...prevData, Expertise: values }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      if (id) {
        await updateLawyer(accessToken, lawyerData);
        await updateImage(accessToken, id, image);
        await resummariseLawyer(accessToken, id);
        alert('Lawyer information updated successfully.');
      }
      //   navigate('/lawyers');
    } catch (error) {
      console.error('Error updating lawyer data:', error);
      alert('Failed to update lawyer information.');
    }
  };

  const handleDeleteImage = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      if (id) {
        setLoading(true);
        await deleteLawyerImage(accessToken, id);
        setImage(null);
        alert(t('imageDeletedSuccessfully'));
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      alert(t('failedToDeleteImage'));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Title>{t('loading')}</Title>
      </Center>
    );
  }

  return (
    <Stack w="90%" align="center">
      <Title mb="lg" style={{ alignSelf: 'flex-start' }}>
        {t('editLawyer')}
      </Title>
      <form onSubmit={handleSubmit} style={{ width: '80%' }}>
        <Paper shadow="sm" p="xl" radius="md" withBorder mb="lg" w="80%" miw="350px">
          <Title order={3} style={{ marginBottom: '1rem' }}>
            {t('lawyerDetails')}
          </Title>
          <Center>
            <Stack gap="md" w="50%" miw="300px">
              <Center>
                <h3>{lawyerData.LawyerName}</h3>
              </Center>
              <Box>
                <Center>
                  <FileInput
                    id="file-input"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={setImage}
                  />
                </Center>
                {image && (
                  <Center mb="sm">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Selected"
                      style={{ maxWidth: '100%', maxHeight: '300px' }}
                    />
                  </Center>
                )}
                <Center>
                  <Button
                    color="green"
                    onClick={() => document.getElementById('file-input')?.click()}
                  >
                    {t('UploadProfileImage')}
                  </Button>
                </Center>
                <br/>
                {image && (
                  <Center>
                    <Button
                      color="red"
                      onClick={handleDeleteImage}
                      disabled={loading} // Disable button while loading
                    >
                      {loading ? <Loader size="xs" /> : t('deleteImage')}
                    </Button>
                  </Center>
                )}

              </Box>
              <TextInput
                label={t('email')}
                name="email"
                value={lawyerData.email || ''}
                onChange={handleChange}
                type="email"
              />
              <TextInput
                label={t('phoneNumber')}
                name="phone"
                value={lawyerData.phone || ''}
                onChange={handleChange}
              />
              <TextInput
                label={t('position')}
                name="Position"
                value={lawyerData.Position || ''}
                onChange={handleChange}
                required
              />
              <TextInput
                label={t('positionPol')}
                name="PositionPol"
                value={lawyerData.PositionPol || ''}
                onChange={handleChange}
                required
              />

              <Select
                label={t('seniority')}
                data={[
                  { value: 'Junior', label: 'Junior' },
                  { value: 'Mid-level', label: 'Mid-level' },
                  { value: 'Senior', label: 'Senior' },
                ]}
                name="Seniority"
                value={lawyerData.Seniority || ''}
                onChange={(_value, option) => {
                  if (option) {
                    setLawyerData((prevData) => ({ ...prevData, Seniority: option.value }));
                  } else {
                    console.log(option);
                    // @ts-ignore
                    setLawyerData((prevData) => ({ ...prevData, Seniority: null }));
                  }
                }}
              />
              <MultiSelect
                label={t('expertise')}
                data={expertiseList}
                value={lawyerData.Expertise || []}
                onChange={handleExpertiseChange}
                placeholder={t('selectExpertise')}
                required
              />
              <Textarea
                label={
                  <Group gap={0} w="200px">
                    {t('bio')}
                    <Tooltip
                      label={
                        <Text
                          size="sm"
                          style={{
                            maxWidth: '200px',
                            whiteSpace: 'normal',
                          }}
                        >
                          {t('lawyerBiography')}
                        </Text>
                      }
                      withArrow
                      w="200px"
                    >
                      <IconInfoCircle
                        size={16}
                        style={{ marginLeft: 5 }}
                        color="var(--mantine-primary-color-filled)"
                      />
                    </Tooltip>
                  </Group>
                }
                name="Bio"
                value={lawyerData.Bio || ''}
                onChange={handleChange}
                placeholder={t('bioPlaceholder')}
                required
                autosize
                minRows={3}
                maxRows={10}
              />
              <Autocomplete
                label={t('location')}
                name="Location"
                data={[t('warsaw'), t('poznan')]}
                value={lawyerData.location}
                onChange={(value) =>
                  setLawyerData((prevData) => ({ ...prevData, location: value }))
                }
                required
              />
              <Group style={{ justifyContent: 'flex-end' }} mt="lg">
                <Button
                  type="submit"
                  color="green"
                  variant="light"
                  leftSection={<IconCheck size={18} />}
                >
                  {t('save')}
                </Button>
              </Group>
            </Stack>
          </Center>
          <div style={{ ...styles.flexRow, marginLeft: '30px' }}>
            <h2>{t('relevantDocuments')}</h2>
            <Tooltip
              label={t('describeLawyerDocuments')}
              withArrow
              multiline
              w={220}
              position="right"
            >
              <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
            </Tooltip>
          </div>
          <BasicTable colLabels={{ Doc: 'doc', Date: 'date' }} data={lawyerData.Docs} />
        </Paper>
      </form>
    </Stack>
  );
};

export default EditLawyerInfo;
