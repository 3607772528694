import { authDelete, authGet, authPost, authPut } from '@/auth/authFetches';

export async function fetchLawyerList(accessToken: string) {
  const path = `/lawyerData/listLawyers`;
  return await authGet(accessToken, path);
}

export async function createLawyer(accessToken: string, lawyerData: any) {
  const path = `/lawyerData/createLawyer`;
  return await authPost(accessToken, path, lawyerData);
}

export async function updateLawyer(accessToken: string, lawyerData: any) {
  const path = `/lawyerData/updateLawyer`;
  return await authPut(accessToken, path, lawyerData);
}

export async function deleteLawyer(accessToken: string, id: string) {
  const path = `/lawyerData/deleteLawyer/${id.replace(" ", "_")}`;
  console.log('Deleting lawyer with id:', id);
  return await authDelete(accessToken, path);
}
