import { Table, Anchor, Button, Modal } from '@mantine/core';
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchProposalList, deleteProposal } from '@/services/proposalsRoutes';
import { IconX, IconEdit } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { Proposal } from '../../interfaces/types';
import { useTranslation } from 'react-i18next';


// A componenet that generates a table row when given a row from proposalData
function ProposalRow({ row, onDelete }: { row: Proposal; onDelete: () => void }) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();


  const proposalId = row.id;
  const lastEditDate =
    row.last_edited instanceof Date ? row.last_edited : row.last_edited ? new Date(row.last_edited) : new Date();
  const formattedDate =
    lastEditDate instanceof Date ? lastEditDate.toISOString().split('T')[0] : '';

  const handleEditClick = () => {
    const newPath = `${location.pathname}/edit-proposal`;
    navigate(newPath, { state: { proposalId: proposalId } });
  };

  const handleDeleteClick = () => {
    async function deleteProposalData() {
      const accessToken = await getAccessTokenSilently();
      await deleteProposal(accessToken, proposalId);
      onDelete(); // Call the callback function after deletion
    }
    deleteProposalData();
  };

  return (
    <Table.Tr key={row.title}>
      <Table.Td>
        <Anchor component="button" fz="sm" onClick={handleEditClick}>
          {row.title}
        </Anchor>
      </Table.Td>
      <Table.Td>{formattedDate}</Table.Td>
      <Table.Td style={{ textAlign: 'right' }}>
        <Button
          variant="light"
          color="green"
          mt="lg"
          style={{ width: 'fit-content', marginRight: '8px' }}
          leftSection={<IconEdit size={16} />}
          onClick={handleEditClick}
        >
          {t('edit')}
        </Button>

        <Button
          variant="light"
          color="red"
          mt="lg"
          style={{ width: 'fit-content' }}
          leftSection={<IconX size={16} />}
          onClick={() => setIsModalOpen(true)}
        >
          {t('delete')}
        </Button>
        <Modal opened={isModalOpen} onClose={() => setIsModalOpen(false)} title={t('confirmDeletion')}>
          <div>{t('confirmDeleteProposal')}</div>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', gap: '12px' }}
          >
            <Button color="gray" onClick={() => setIsModalOpen(false)}>
              {t('cancel')}
            </Button>
            <Button color="red" onClick={handleDeleteClick}>
              {t('confirm')}
            </Button>
          </div>
        </Modal>
      </Table.Td>
    </Table.Tr>
  );
}

export default function ProposalTable() {
  const [proposalData, setProposalData] = useState<Proposal[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    console.log('Fetching data');
    const accessToken = await getAccessTokenSilently();
    console.log('Token received');
    const fetchedData = await fetchProposalList(accessToken);
    const modifiedData = fetchedData?.map((item: Proposal) => {
      return {
        ...item,
        last_edited: item.last_edited ? new Date(item.last_edited) : undefined,
      };
    });
    setProposalData(modifiedData);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Table.ScrollContainer minWidth={600} w="80%">
      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t('proposal')}</Table.Th>
            <Table.Th>{t('dateEdited')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {proposalData?.map((row) => <ProposalRow key={row.id} row={row} onDelete={fetchData} />)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
