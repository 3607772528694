import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EditLawyer from './pages/ViewLawyers/ViewLawyers';
import { c } from 'vite/dist/node/types.d-aGj9QkWt';
// import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      // English translations
      welcome: 'Welcome',
      addRow: 'Add Row',
      removeRow: 'Remove Row',
      createNew: 'Create New',
      edit: 'Edit',
      delete: 'Delete',
      logIn: 'Log In',
      logOut: 'Log Out',
      fileUpload: 'File Upload',
      proposals: 'Proposals',
      switchLanguage: 'Switch Language',
      language: 'Proposal Language',
      changeAccount: 'Change Account',
      proposal: 'Proposal',
      dateEdited: 'Last Edited',
      editProposal: 'Edit Proposal',
      title: 'Title',
      description: 'Description',
      caseInfo: 'Case Information',
      lawyerInput: 'User Input',
      generateProposal: 'Generate Proposal',
      scopeOfWork: 'Scope of Work',
      introduction: 'Introduction',
      assumptions: 'Assumptions',
      fees: 'Fees',
      position: 'Position - English',
      positionPol: 'Position - Polish',
      rate: 'Rate (EUR)',
      generatedByAI: 'Generated by AI',
      lawyers: 'Lawyers',
      experience: 'Experiences',
      projectTitle: 'Project Title',
      client: 'Client',
      addExperience: 'Add Experience',
      addLawyer: 'Add Lawyer',
      save: 'Save',
      cancelChanges: 'Cancel Changes',
      generateWordDoc: 'Generate Word Document',
      deleteProposal: 'Delete Proposal',
      addYourTitleHere: 'Add your title here',
      provideCaseInfo:
        'Provide information pertaining to the case e.g. an email from a potential client or simply a description of it. This will be used by an LLM to assign lawyers automatically.',
      addYourInputTextHere: 'Add your input text here.',
      clientName: 'Representative',
      clientCompany: 'Company',
      addYourIntroHere: 'Add your intro here',
      addScopeOfWorkHere: 'Add scope of work here',
      addAssumptionsHere: 'Add assumptions here',
      llmAssignedLawyers:
        'An LLM has automatically assigned lawyers and explained their suitability for the task given their experience.',
      loading: 'Loading...',
      loadingBio: 'Loading Bio...',
      confirmDeletion: 'Confirm Deletion',
      confirmDeleteProposal: 'Are you sure you want to delete this proposal?',
      confirm: 'Confirm',
      humanInput: 'The fields below should be filled in by a human',
      introLetter: `
Dear _____

thank you for inviting the law firm WKB Wierciński, Kwieciński, Baehr ("WKB") to present an offer of legal assistance for _____.

In response to your request, below we provide information about the firm, details related to the scope of our advice in this proposal and present our previous experience and the team of lawyers dedicated to the assignment.

In our opinion, the knowledge and experience of our team members ensure that WKB can offer you comprehensive legal services at the highest level. We hope that our offer will meet your expectations. 

If you have any question, do not hesitate to contact us. We will be very happy to answer any of them you may have.

Kind regards,
`,
      introLine1:
        'thank you for inviting the law firm WKB Wierciński, Kwieciński, Baehr ("WKB") to present an offer of legal assistance for',
      introRest: `In response to your request, below we provide information about the firm, details related to the scope of our advice in this proposal and present our previous experience and the team of lawyers dedicated to the assignment.

In our opinion, the knowledge and experience of our team members ensure that WKB can offer you comprehensive legal services at the highest level. We hope that our offer will meet your expectations. 

If you have any question, do not hesitate to contact us. We will be very happy to answer any of them you may have.

Kind regards,`,
      uploadedFiles: 'Uploaded Files',
      searchFiles: 'Search files...',
      dragDrop: "Drag 'n' drop some files here, or click to select files",
      errorFetchingAlert: 'Failed to fetch file list.',
      successfulFileUpload: 'Files uploaded successfully:',
      unsuccessfulFileUpload: 'Files not uploaded successfully:',
      noFilesUploaded: 'None',
      noMatchFiles: 'No files match the search criteria.',
      cancel: 'Cancel',
      deleteFileConfirmation: 'Are you sure you want to delete',
      name: 'Full Name',
      surname: 'Surname',
      email: 'Email',
      phoneNumber: 'Phone Number',
      positionAccountPage: 'Position',
      location: 'Location',
      userDetails: 'User Details',
      addDescription: 'Add description',
      addBio: 'Add biography',
      experienceIntro: 'Experiences Introduction',
      addExperienceIntroHere: 'Add experiences introduction here',

      // Add other translations here
      account: 'Account',
      editLawyerInfo: 'Edit Lawyer Information',
      lawyerName: "Lawyer's Full Name",
      editLawyer: 'Edit Lawyer',
      expertise: 'Expertise',
      seniority: 'Seniority',
      selectExpertise: 'Select expertise',
      searchLawyer: 'Search by lawyer name',
      lawyerDetails: 'Lawyer Details',
      bio: 'Bio',
      requiredLawyers: 'Required Lawyers',
      selectRequiredLawyers: 'Select required lawyers',
      numberOfLawyers: 'Number of Lawyers',
      constraints: 'Requirements',
      provideConstraints: 'Provide requirements for the proposal',
      teamComposition: 'Team Composition',
      provideTeamComposition:
        'Define additional team members and what relevant expertise they require (if any).',
      representatives: 'Representatives',
      selectRepresentatives: 'Select representatives',
      warsaw: 'Warsaw',
      poznan: 'Poznań',
      relevantDocuments: 'Documents',
      describeLawyerDocuments:
        'Documents the LLM has decided have experiences relevant to this lawyer.',
      doc: 'Document',
      date: 'Date',
      quantity: 'Quantity',
      provideSeniority: 'List what minimum requirements of seniority levels are needed on the team.',
      feeType: 'Fee Type',
      currency: 'Currency',
      retainer: 'Retainer',
      hourly: 'Hourly Rate',
      blendedRate: 'Blended Rate',
      lumpSum: 'Lump Sum',
      retainerAmount: 'Retainer Amount',
      billingFrequency: 'Billing Frequency',
      weekly: 'Weekly',
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      yearly: 'Yearly',
      pickALawyer: 'Pick a lawyer',
      english: 'English',
      polish: 'Polish',
      lawyerBiography:
        "Additional biographical information about the lawyer to be fed into the LLM for proposal generation - i.e. information that is unlikely to appear in the documents already uploaded or hasn't been put anywhere all in one place.",
      deleteImage: 'Delete Profile Image',
      UploadProfileImage: 'Upload Profile Image',
      wkbRep: "WKB Representatives",
      proposalLanguageTooltip: "The language the proposal will be generated in.",
      numberOfExperiences: "Number of Experiences",
      teamCompositionComplaint: "Team composition constraints must be defined. Please add at least one team, or add at least one required lawyer.",
      provideContextFile: "Drag and drop a file pertaining to the case here or click to upload",
      representativesTooltip: 'The WKB representatives writing the letter to the client. Note - the first person in this list will have their contact details automatically entered at the bottom of the word template.',
      clientSectorPlaceholder: 'Input client sector here',
      clientSector: 'Client Sector',
      proposalDate: 'Proposal Date',
      cancelGeneration: "Cancel Generating"
    },
  },
  pl: {
    translation: {
      // Polish translations
      welcome: 'Witamy',
      addRow: 'Dodaj wiersz',
      removeRow: 'Usuń wiersz',
      createNew: 'Utwórz nowy',
      edit: 'Edytuj',
      delete: 'Usuń',
      logIn: 'Zaloguj',
      logOut: 'Wyloguj',
      fileUpload: 'Przesyłanie plików',
      proposals: 'Oferty',
      switchLanguage: 'Zmień język',
      language: 'Język oferty',
      changeAccount: 'Zmień konto',
      proposal: 'Propozycja',
      dateEdited: 'Ostatnio Edytowane',
      editProposal: 'Edytuj ofertę',
      title: 'Tytuł',
      description: 'Opis',
      caseInfo: 'Informacje dotyczące sprawy',
      lawyerInput: 'Sekcja do wypełnienia przez użytkownika',
      generateProposal: 'Wygeneruj ofertę',
      scopeOfWork: 'Zakres Doradztwa',
      introduction: 'Wstęp',
      assumptions: 'Założenia',
      fees: 'Propozycja Wynagrodzenia',
      position: 'Stanowisko - Angielski',
      positionPol: 'Stanowisko - Polski',
      rate: 'Stawka (EUR)',
      generatedByAI: 'Wygenerowane przez AI',
      lawyers: 'Prawnicy',
      experience: 'Przykładowe doświadczenia',
      projectTitle: 'Tytuł projektu',
      client: 'Klient',
      addExperience: 'Dodaj doświadczenie',
      addLawyer: 'Dodaj prawnika',
      save: 'Zapisz',
      cancelChanges: 'Anuluj zmiany',
      generateWordDoc: 'Generuj dokument Word',
      deleteProposal: 'Usuń propozycję',
      addYourTitleHere: 'Dodaj swój tytuł tutaj',
      provideCaseInfo: 'Podaj szczegóły dotyczace sprawy, np. e-mail od potencjalnego klienta lub krótki jej opis. Informacje te zostana wykorzystane przez LLM do automatycznego przypisania odpowiednich prawników.',
      addYourInputTextHere: 'Wprowadź tekst tutaj',
      clientName: 'Przedstawiciel',
      clientCompany: 'Firma',
      addYourIntroHere: 'Dodaj swój wstęp tutaj',
      addScopeOfWorkHere: 'Dodaj zakres pracy tutaj',
      addAssumptionsHere: 'Dodaj założenia tutaj',
      llmAssignedLawyers:
        'LLM automatycznie przypisał prawników i wyjaśnił ich dopasowanie do zadania w oparciu o ich doświadczenie.',
      loading: 'Ładowanie...',
      loadingBio: 'Ładowanie biografii...',
      confirmDeletion: 'Potwierdź usunięcie',
      confirmDeleteProposal: 'Czy na pewno chcesz usunąć tę propozycję?',
      confirm: 'Potwierdź',
      humanInput: 'Poniższe pola powinny zostać wypełnione przez użytkownika',
      introLetter: `
Szanowna Pani/Panie _____

dziękujemy za zaproszenie kancelarii WKB Wierciński, Kwieciński, Baehr sp. k. („WKB”) do przedstawienia oferty współpracy na świadczenie usług w zakresie […]

W odpowiedzi na Państwa zapytanie, poniżej przedstawiamy informacje o kancelarii, szczegóły związane z zakresem naszego doradztwa w ramach niniejszej oferty oraz prezentujemy nasze dotychczasowe doświadczenie i zespół prawników dedykowanych do realizacji zlecenia.

W naszej ocenie wiedza i doświadczenie członków naszego zespołu sprawia, że WKB może zaoferować Państwu kompleksową obsługę prawną na najwyższym poziomie. Mamy nadzieję, że nasza oferta spełni Państwa oczekiwania.

W przypadku jakichkolwiek pytań, uprzejmie prosimy o kontakt. Bardzo chętnie odpowiemy na wszelkie Państwa pytania.

Z poważaniem`,

      introLine1:
        'dziękujemy za zaproszenie kancelarii WKB Wierciński, Kwieciński, Baehr sp. k. („WKB”) do przedstawienia oferty współpracy na świadczenie usług w zakresie […]',
      introRest: `W odpowiedzi na Państwa zapytanie, poniżej przedstawiamy informacje o kancelarii, szczegóły związane z zakresem naszego doradztwa w ramach niniejszej oferty oraz prezentujemy nasze dotychczasowe doświadczenie i zespół prawników dedykowanych do realizacji zlecenia.

W naszej ocenie wiedza i doświadczenie członków naszego zespołu sprawia, że WKB może zaoferować Państwu kompleksową obsługę prawną na najwyższym poziomie. Mamy nadzieję, że nasza oferta spełni Państwa oczekiwania.

W przypadku jakichkolwiek pytań, uprzejmie prosimy o kontakt. Bardzo chętnie odpowiemy na wszelkie Państwa pytania.

Z poważaniem`,
      uploadedFiles: 'Przesłane pliki',
      searchFiles: 'Wyszukaj w plikach...',
      dragDrop: 'Przeciągnij i upuść pliki tutaj lub kliknij, aby wybrać pliki',
      errorFetchingAlert: 'Nie udało się pobrać listy plików.',
      successfulFileUpload: 'Pliki przesłane pomyślnie:',
      unsuccessfulFileUpload: 'Pliki nie zostały przesłane pomyślnie:',
      noFilesUploaded: 'Brak',
      noMatchFiles: 'Brak plików spełniających kryteria wyszukiwania.',
      cancel: 'Anuluj',
      deleteFileConfirmation: 'Czy na pewno chcesz usunąć',
      name: 'Imię i Nazwisko',
      surname: 'Nazwisko',
      email: 'Email',
      phoneNumber: 'Numer telefonu',
      positionAccountPage: 'Stanowisko',
      location: 'Lokalizacja',
      userDetails: 'Dane użytkownika',
      addDescription: 'Dodaj opis',
      addBio: 'Dodaj biografię',
      experienceIntro: 'Wstęp do doświadczeń',
      addExperienceIntroHere: 'Dodaj wstęp do doświadczeń',

      // Add other translations here
      account: 'Konto',
      editLawyerInfo: 'Edytuj informacje o prawniku',
      lawyerName: 'Imię i nazwisko prawnika',
      editLawyer: 'Edytuj prawnika',
      expertise: 'Ekspertyza',
      seniority: 'Staż pracy',
      selectExpertise: 'Wybierz ekspertyzę',
      searchLawyer: 'Wyszukaj po nazwie prawnika',
      lawyerDetails: 'Szczegóły prawnika',
      bio: 'Biografia',
      requiredLawyers: 'Wymagani prawnicy',
      selectRequiredLawyers: 'Wybierz wymaganych prawników',
      numberOfLawyers: 'Liczba prawników',
      constraints: 'Wymagania',
      provideConstraints: 'Określ wymagania dotyczace oferty.',
      teamComposition: 'Skład zespołu',
      provideTeamComposition: 'Zdefiniuj dodatkowych czlonków zespotu oraz wymagane obszary ekspertyzy (jesli takie sa).',   
      representatives: 'Przedstawiciele',
      selectRepresentatives: 'Wybierz przedstawicieli',
      warsaw: 'Warszawa',
      poznan: 'Poznań',
      relevantDocuments: 'Dokumenty',
      describeLawyerDocuments:
        'Dokumenty, które LLM uznał za doświadczenia istotne dla tego prawnika.',
      doc: 'Dokument',
      date: 'Data',
      quantity: 'Ilość',
      provideSeniority: 'Podaj minimalne wymagania dotyczące poziomów stażu pracy w zespole.',
      feeType: 'Typ opłaty',
      currency: 'Waluta',
      retainer: 'Zaliczka',
      hourly: 'Stawka godzinowa',
      blendedRate: 'Stawka mieszana',
      lumpSum: 'Stawka ryczałtowa',
      retainerAmount: 'Kwota zaliczki',
      billingFrequency: 'Częstotliwość rozliczeń',
      weekly: 'Tygodniowo',
      monthly: 'Miesięcznie',
      quarterly: 'Kwartalnie',
      yearly: 'Rocznie',
      pickALawyer: 'Wybierz prawnika',
      english: 'Angielski',
      polish: 'Polski',
      lawyerBiography:
        'Dodatkowe informacje biograficzne o prawniku do wprowadzenia do LLM w celu generowania propozycji - tj. informacje, które są mało prawdopodobne, że pojawią się w już przesłanych dokumentach lub nie zostały umieszczone w jednym miejscu.',
      deleteImage: 'Usuń zdjęcie profilowe',
      UploadProfileImage: "Prześlij zdjęcie profilowe",
      wkbRep: "Przedstawiciele WKB",
      proposalLanguageTooltip: "Język w którym wygenerowana zostanie oferta.",
      numberOfExperiences: "Liczba doświadczeń",
      teamCompositionComplaint: "Wymagane jest zdefiniowanie ograniczeń składu zespołu. Proszę dodać przynajmniej jeden zespół lub dodać przynajmniej jednego wymaganego prawnika.",
      provideContextFile: "Przeciągnij i upuść plik dotyczący sprawy tutaj lub kliknij, aby przesłać.",
      representativesTooltip: 'Przedstawiciele WKB odpowiedzialni za napisanie listu do klienta. Uwaga: dane kontaktowe pierwszej osoby z listy zostaną automatycznie dodane na końcu dokumentu Word.',
      clientSectorPlaceholder: 'Wprowadź sektor klienta',
      clientSector: 'Sektor Klienta',
      proposalDate: 'Data Oferty',
      cancelGeneration: "Anuluj generowanie"
    },
  },
};

i18n
  //   .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'pl', // Default language
    debug: true,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
