import { useReducer, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Divider,
  Textarea,
  TextInput,
  Tooltip,
  Button,
  Loader,
  Group,
  Stack,
  Switch,
  SegmentedControl,
  Text,
} from '@mantine/core';
import { IconPlus, IconInfoCircle } from '@tabler/icons-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { proposalReducer, initialProposalState, PROPOSALACTIONS } from './reducers/proposalReducer';
import { userFeedbackReducer, initialUserFeedbackState } from './reducers/userFeedbackReducer';
import { otherDataReducer, initialOtherState } from './reducers/otherDataReducer';
// import {
//   caseInformationReducer,
//   initialCaseInformationState,
// } from './reducers/caseInformationReducer';

import AIGeneratedSection from './AIGeneratedSection';
import ActionButtons from './ActionButtons';
import HumanGeneratedSection from './HumanGeneratedSection';
import CaseInfoSection from './CaseInfoSection';

import { fetchProposal } from '@/services/proposalsRoutes';
import { styles } from './styles';

export default function EditProposalPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const { getAccessTokenSilently, user } = useAuth0();
  const ref = useRef<{ handleGenerate: () => void } | null>(null);

  // Setting up reducers
  //   const [caseInformation, dispatchCaseInformation] = useReducer(
  //     caseInformationReducer,
  //     initialCaseInformationState
  //   );
  const [proposal, dispatchProposal] = useReducer(proposalReducer, initialProposalState);
  const [userFeedback, dispatchUserFeedback] = useReducer(
    userFeedbackReducer,
    initialUserFeedbackState
  );
  const [otherData, dispatchOtherData] = useReducer(otherDataReducer, initialOtherState);

  const { title, input } = proposal;
  const { loading } = userFeedback;
  const { documentLanguage } = otherData;
  const { proposalId } = location.state as { proposalId: string };

  // Fetching data on mount
  useEffect(() => {
    // Fetch Proposal Data
    const fetchProposalData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const fetchedData = await fetchProposal(accessToken, proposalId);
        console.log(fetchedData);
        if (fetchedData) {
          dispatchProposal({
            type: PROPOSALACTIONS.INITIALIZE,
            payload: {
              id: fetchedData.id,
              title: fetchedData.title,
              input: fetchedData.input,
              sector: fetchedData.sector,
              constraints: fetchedData.constraints,
              client: fetchedData.client,
              intro: fetchedData.intro,
              representatives: fetchedData.representatives || [],
              scope: fetchedData.scope,
              assumptions: fetchedData.assumptions,
              fees: fetchedData.fees || [],
              lawyers: fetchedData.lawyers || {},
              experienceIntro: fetchedData.experienceIntro || '',
              experience: fetchedData.experience || [],
              proposalDate: fetchedData.proposalDate,
            },
          });
        }
      } catch (error) {
        console.error('Error fetching proposal:', error);
        alert('Failed to fetch proposal data.');
      }
    };

    fetchProposalData();
  }, [proposalId, getAccessTokenSilently]);

  return (
    <div style={styles.container}>
      <h1>
        {t('editProposal')} - {title}
      </h1>

      {/* Title Section */}
      <Group justify="space-between">
        <div style={{ ...styles.section }}>
          <h2>{t('title')}</h2>
          <TextInput
            miw="200px"
            value={title}
            placeholder={t('addYourTitleHere')}
            onChange={(e) =>
              dispatchProposal({
                type: PROPOSALACTIONS.SET_FIELD,
                field: 'title',
                value: e.target.value,
              })
            }
            style={styles.inputWidth}
          />
        </div>

        {/* <Switch onLabel={t('polish')} offLabel={t('english')} size="xl" /> */}
      </Group>
      <Stack gap={0}>
        <div style={styles.flexRow}>
          <h2>{t('language')}</h2>
          <Tooltip
            label={t('proposalLanguageTooltip')}
            withArrow
            multiline
            position="right"
            w={220}
          >
            <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
          </Tooltip>
        </div>
        <SegmentedControl
          maw="300px"
          data={[
            { label: t('polish'), value: 'pl' },
            { label: t('english'), value: 'en' },
          ]}
          value={documentLanguage}
          onChange={(value) => {
            // value is now 'pl' or 'en', independent of translation
            dispatchOtherData({
              type: 'SET_FIELD',
              field: 'documentLanguage',
              value: value, 
            });
          }}
          color="blue"
        />
      </Stack>

      <CaseInfoSection
        proposal={proposal}
        dispatchProposal={dispatchProposal}
        userFeedback={userFeedback}
        dispatchUserFeedback={dispatchUserFeedback}
        otherData={otherData}
        dispatchOtherData={dispatchOtherData}
        reference={ref}
      />

      <Divider my="xl" size="sm" />

      <HumanGeneratedSection
        proposal={proposal}
        dispatchProposal={dispatchProposal}
        userFeedback={userFeedback}
        dispatchUserFeedback={dispatchUserFeedback}
        otherData={otherData}
        dispatchOtherData={dispatchOtherData}
      />

      <Divider my="xl" size="sm" />

      <AIGeneratedSection
        proposal={proposal}
        dispatchProposal={dispatchProposal}
        userFeedback={userFeedback}
        dispatchUserFeedback={dispatchUserFeedback}
        otherData={otherData}
        dispatchOtherData={dispatchOtherData}
        ref={ref}
      />
      {/* Action Buttons */}
      <ActionButtons
        proposal={proposal}
        userFeedback={userFeedback}
        otherData={otherData}
        dispatchUserFeedback={dispatchUserFeedback}
      />
    </div>
  );
}
