import React, { useState, useEffect } from 'react';
import { Table, Select, Button, Box, Textarea, Group, LoadingOverlay } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../components/TextEditor/TextEditor';
import { PROPOSALACTIONS } from './reducers/proposalReducer';
import { authWebSocket } from '@/auth/authFetches';
import { useAuth0 } from '@auth0/auth0-react';
import { Proposal } from '@/interfaces/types';

// REDO THIS PROPERLY LATER SO THAT PAYLOAD ISN'T 'ANY' ANYMORE - THIS TYPING SHOULD PROBABLY BE DONE SOMEWHERE IN THE REDUCER
type LawyerTableProps = {
  allLawyers: { [key: string]: string };
  selectedLawyers: { [key: string]: { name: string; bio: string } };
  dispatchProposal: React.Dispatch<React.SetStateAction<{ type: string; payload: any }>>;
  //   handleLawyerGenerationMessage: (data: any) => void;
  otherData: any;
  proposal: Proposal;
};

const LawyerTable: React.FC<LawyerTableProps> = ({
  allLawyers,
  proposal,
  otherData,
  selectedLawyers,
  dispatchProposal,
}) => {
  const [rows, setRows] = useState<string[]>(['']);
  const [maxWidth, setMaxWidth] = useState<string>('auto');
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const { documentLanguage } = otherData;
  const { input } = proposal;
  const reverseMapping = Object.fromEntries(Object.entries(allLawyers).map(([a, b]) => [b, a]));
  const [loadingRows, setLoadingRows] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    if (Object.keys(selectedLawyers).length === 0) {
      setRows(['']);
    } else {
      setRows(Object.keys(selectedLawyers));
    }

    const longestLawyerName = Object.values(allLawyers).reduce(
      (longest, current) => (current.length > longest.length ? current : longest),
      ''
    );
    const maxLength = longestLawyerName.length * 8;
    setMaxWidth(`${maxLength}px`);
  }, [allLawyers, selectedLawyers]);

  useEffect(() => {
    const timeouts = Object.keys(loadingRows).map((key) =>
      setTimeout(() => {
        setLoadingRows((prev) => ({ ...prev, [key]: false }));
      }, 15000) // 15 seconds timeout
    );
  
    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [loadingRows]);
  

  const addRow = () => setRows((prev) => [...prev, '']);

  const handleSelectChange = (lawyerId: string, rowIndex: number) => {
    const lawyerName = allLawyers[lawyerId];
    setLoadingRows((prev) => ({ ...prev, [rowIndex]: true }));
    handleGetLawyerBio(lawyerName, rowIndex);
    // Adding new lawyer to proposal
    dispatchProposal({
      type: PROPOSALACTIONS.ADD_LAWYER,
      payload: {
        lawyerId,
        name: lawyerName,
        bio: selectedLawyers[lawyerId]?.bio || '',
      },
    });
    // Removing old lawyer from proposal
    dispatchProposal({
      type: PROPOSALACTIONS.REMOVE_LAWYER,
      payload: rows[rowIndex],
    });
    // Updating rows
    setRows((prev) => prev.map((row, index) => (index === rowIndex ? lawyerId : row)));
  };

  const handleBioChange = (lawyerId: string, bio: string) => {
    dispatchProposal({
      type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
      payload: {
        lawyerId,
        bio,
      },
    });
  };

  const removeRow = (rowIndex: number) => {
    const lawyerId = rows[rowIndex];
    setRows((prev) => prev.filter((_, index) => index !== rowIndex));
    const newLawyers = { ...selectedLawyers };
    delete newLawyers[lawyerId];
    dispatchProposal({ type: PROPOSALACTIONS.REMOVE_LAWYER, payload: lawyerId });
  };

  const getAvailableLawyers = (currentLawyerId: string) => {
    const selectedLawyerIds = new Set(Object.keys(selectedLawyers));
    return Object.keys(allLawyers)
      .filter((key) => key === currentLawyerId || !selectedLawyerIds.has(key))
      .map((key) => ({
        value: key,
        label: allLawyers[key],
      }));
  };

  // Handle WebSocket Messages for Lawyers
  const handleLawyerGenerationMessage = (message: any) => {
    console.log('Lawyer generation message: ', message);
    if (message.type === 'selected_lawyers') {
      const lawyerNames = message.data;
      lawyerNames.forEach((lawyerName: string) => {
        if (!(lawyerName in selectedLawyers)) {
          dispatchProposal({
            type: PROPOSALACTIONS.ADD_LAWYER,
            payload: { lawyerId: lawyerName, bio: '', name: allLawyers[lawyerName] },
          });
        }
      });
    } else if (message.type === 'bio_response') {
      const { lawyer, bio } = message.data;
      dispatchProposal({
        type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
        payload: { lawyerId: reverseMapping[lawyer], bio },
      });
    } else if (message.type === 'completed') {
      ws?.close();
      console.log('Lawyer loading completed.');
    }
  };

  // Handle Generate
  const handleGetLawyerBio = (lawyerName: string, rowIndex: number) => {
    const generate = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const newws = authWebSocket(accessToken);
        setWs(newws);

        newws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.source === 'offer_creation_lawyers') {
            handleLawyerGenerationMessage(data);
            if (data.type === 'bio_response') {
              setLoadingRows((prev) => ({ ...prev, [rowIndex]: false}));
            }
          }
        };

        newws.onopen = () => {
          // If team constraints exist, send separate requests for each team.
          // Otherwise send one request as before for all members
          newws.send(
            JSON.stringify({
              action: 'generateLawyers',
              lawyer_names: [lawyerName],
              language: documentLanguage,
              query: input,
            })
          );
        };
      } catch (error) {
        console.error('Error establishing WebSocket connection:', error);
      }
    };
    generate();
  };

  return (
    <div>
      <Table>
        <tbody>
          {rows.map((lawyerId, index) => (
            <tr key={index}>
              <td>
                <Box style={{ position: 'relative', paddingBottom: '1rem' }}>
                  {/* Lawyer Select Dropdown */}
                  <Group>
                    <Select
                      placeholder={t('pickALawyer')}
                      data={getAvailableLawyers(lawyerId)}
                      value={lawyerId || ''}
                      onChange={(value) => handleSelectChange(value || '', index)}
                      searchable
                      style={{ width: maxWidth, marginBottom: '0.5rem' }}
                    />
                    {/* <Button
                    color="blue"
                    variant="light"
                    leftSection={documentLoading ? <Loader size="xs" color="blue" /> : <IconPlus size={18} />}
                    onClick={handleWordDocGenerate}
                    >
                    {t('generateWordDoc')}
                    </Button> */}
                  </Group>
                  {/* TextEditor for Bio */}
                  {/* <TextEditor
                    content={selectedLawyers[lawyerId]?.bio || ''} // Ensure bio is a string
                    onUpdate={(value) => handleBioChange(lawyerId, value || '')} // Handle bio updates
                    placeholder="Add bio"
                    disabled={!lawyerId} // Disable the TextEditor if no lawyer is selected
                  /> */}
                  <div style={{ position: 'relative' }}>
                  <LoadingOverlay visible={loadingRows[index]} zIndex={1} overlayProps={{ radius: "sm" }}/>
                  <Textarea
                    value={selectedLawyers[lawyerId]?.bio || ''}
                    placeholder={loadingRows[index] ? t('loading') : t('addBio')}
                    onChange={(event) => handleBioChange(lawyerId, event.target.value || '')}
                    variant="filled"
                    autosize
                    minRows={6}
                    disabled={loadingRows[index]}
                  />
                  </div>
                  {/* Remove Button */}
                  <Button
                    variant="light"
                    color="red"
                    size="compact-xs"
                    onClick={() => removeRow(index)}
                    style={{
                      position: 'absolute',
                      top: '4px',
                      right: '4px',
                      zIndex: 10,
                    }}
                  >
                    <IconX size={16} />
                  </Button>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="light" onClick={addRow} mt="md">
        {t('addLawyer')}
      </Button>
    </div>
  );
};

export default LawyerTable;