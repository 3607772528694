import React from 'react';
import { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { IconInfoCircle, IconPlus } from '@tabler/icons-react';
import { Title, Tooltip, Button, Loader } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import LawyerTable from './LawyerTable';
import ExperienceTable from './ExperienceTable';
import { getLawyerList } from '@/services/proposalsRoutes';
import { authWebSocket } from '../../auth/authFetches';
import { PROPOSALACTIONS } from './reducers/proposalReducer';
import { USERFEEDBACKACTIONS } from './reducers/userFeedbackReducer';

import { Proposal } from '@/interfaces/types';
import { styles } from './styles';
import { OTHERDATAACTIONS } from './reducers/otherDataReducer';

type ProposalRef = {
  handleGenerate: () => void;
  handleCancel: () => void;
};


const AIGeneratedSection = forwardRef(
  (
    {
      proposal,
      dispatchProposal,
      userFeedback,
      dispatchUserFeedback,
      otherData,
      dispatchOtherData,
    }: {
      proposal: Proposal;
      dispatchProposal: React.Dispatch<any>;
      userFeedback: any;
      dispatchUserFeedback: React.Dispatch<any>;
      otherData: any;
      dispatchOtherData: React.Dispatch<any>;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();

    const { lawyers, experience, input, sector, constraints } = proposal;
    const { allLawyers, documentLanguage, neededLawyerCompletions, currentLawyerCompletions } =
      otherData;
    const {
      loading,
      loadingLawyers,
      loadingExperiences,
      lawyerLoadingState,
      experienceLoadingState,
    } = userFeedback;
    const reverseMapping = Object.fromEntries(Object.entries(allLawyers).map(([a, b]) => [b, a]));
    console.log('needed lawyer completions is', neededLawyerCompletions);
    console.log('current lawyer completions is', currentLawyerCompletions);

    // Fetching data on mount
    useEffect(() => {
      // Fetch Lawyer List
      const fetchLawyerList = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await getLawyerList(accessToken);
          dispatchOtherData({ field: 'allLawyers', value: response });
        } catch (error) {
          console.error('Error fetching lawyer list:', error);
          alert('Failed to fetch lawyer list.');
        }
      };

      fetchLawyerList();
    }, [getAccessTokenSilently, dispatchOtherData, getLawyerList]);

    useImperativeHandle(ref, () => {
      return {
        handleGenerate,
      };
    });

    useImperativeHandle(ref, () => ({
      handleGenerate,
      handleCancel: () => {
        if (ws) {
          ws.close();
          setWs(null);
        }
    
        // Reset loading states
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: false });
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: false });
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });
    
        // Reset counters if needed
        dispatchOtherData({ field: 'currentLawyerCompletions', value: 0 });
      },
    }));

    // Update Total Loading State
    useEffect(() => {
      dispatchUserFeedback({
        type: USERFEEDBACKACTIONS.SET_LOADING,
        field: 'loading',
        value: loadingLawyers || loadingExperiences,
      });
    }, [loadingLawyers, loadingExperiences]);

    // WebSocket State
    const [ws, setWs] = React.useState<WebSocket | null>(null);

    // Close WebSocket when loading completes
    useEffect(() => {
      if (currentLawyerCompletions >= neededLawyerCompletions && !loadingExperiences && ws) {
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, payload: false });
        dispatchOtherData({ field: 'currentLawyerCompletions', value: 0 });
        console.log('Lawyer loading completed.');
        console.log('Both tasks are done. Closing WebSocket.');
        ws.close();
      }
    }, [currentLawyerCompletions, neededLawyerCompletions, loadingExperiences, ws]);

    // Handle WebSocket Messages for Lawyers
    const handleLawyerGenerationMessage = (message: any) => {
      console.log('Lawyer generation message: ', message);
      if (message.type === 'selected_lawyers') {
        const lawyerNames = message.data;
        lawyerNames.forEach((lawyerName: string) => {
          if (!(lawyerName in lawyers)) {
            dispatchProposal({
              type: PROPOSALACTIONS.ADD_LAWYER,
              payload: { lawyerId: lawyerName, bio: '', name: allLawyers[lawyerName] },
            });
          }
        });
      } else if (message.type === 'bio_response') {
        const { lawyer, bio } = message.data;
        dispatchProposal({
          type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
          payload: { lawyerId: lawyer, bio },
        });
      } else if (message.type === 'completed') {
        dispatchOtherData({
          type: OTHERDATAACTIONS.INCREMENT_CURRENT_LAWYER_COMPLETIONS,
        });
      } else {
        dispatchUserFeedback({
          type: USERFEEDBACKACTIONS.SET_LAWYER_LOADING_STATE,
          value: message.type,
        });
      }
    };

    // Handle WebSocket Messages for Experiences
    const handleExperienceGenerationMessage = (message: any) => {
      console.log('experience generation message: ', message);
      if (message.type === 'selected_experiences') {
        for (const experience of message.data) {
          dispatchProposal({
            type: PROPOSALACTIONS.ADD_EXPERIENCE,
            payload: experience,
          });
        }
      } else if (message.type === 'completed') {
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });
        console.log('Experiences loading completed.');
      } else {
        dispatchUserFeedback({
          type: USERFEEDBACKACTIONS.SET_EXPERIENCE_LOADING_STATE,
          value: message.type,
        });
      }
    };

    // =====================

    const handleRequiredLawyers = () => {
      if (constraints && constraints.requiredLawyers && constraints.requiredLawyers.length > 0) {
        for (let lawyerId of constraints.requiredLawyers) {
          const lawyerName = allLawyers[lawyerId];
          dispatchProposal({
            type: PROPOSALACTIONS.ADD_LAWYER,
            payload: {
              lawyerId,
              name: lawyerName,
              bio: '',
            },
          });

          handleGetLawyerBio(lawyerName);
        }
      }
    };

    // Handle Generate
    const handleGetLawyerBio = (lawyerName: string) => {
      const generate = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const newws = authWebSocket(accessToken);
          setWs(newws);

          newws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'bio_response') {
              const { lawyer, bio } = data.data;
              dispatchProposal({
                type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
                payload: { lawyerId: reverseMapping[lawyer], bio },
              });
              //   dispatchProposal({
              //     type: PROPOSALACTIONS.EDIT_LAWYER_BIO,
              //     payload: {
              //       lawyerId: lawyer,
              //       bio,
              //     },
              //   });
            } else if (data.type === 'completed') {
              ws?.close();
              console.log('Lawyer loading completed.');
            }
          };

          newws.onopen = () => {
            // If team constraints exist, send separate requests for each team.
            // Otherwise send one request as before for all members
            newws.send(
              JSON.stringify({
                action: 'generateLawyers',
                lawyer_names: [lawyerName],
                language: documentLanguage,
                query: input,
              })
            );
          };
          return newws;
        } catch (error) {
          console.error('Error establishing WebSocket connection:', error);
        }
      };
      generate();
    };

    //==============================
    const handleCancel = () => {
      if (ws) {
        ws.close();
        setWs(null);
      }

      // Reset loading states
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: false });
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: false });
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });

      // Reset counters if needed
      dispatchOtherData({ field: 'currentLawyerCompletions', value: 0 });
    }
    // Handle Generate
    const handleGenerate = () => {
      let needLawyers: boolean = true;
      if (!constraints || !constraints.teamComposition || constraints.teamComposition.length === 0)
          {
              // If required lawyers are specified, but not team composition is specified, generate the required lawyers
          if (constraints.requiredLawyers && constraints.requiredLawyers.length > 0) {
            needLawyers = false;
            handleRequiredLawyers();
          } else { // In this case, no lawyers have been specified at all through team constraints or explicit naming, we do not want to handle this case
              alert(t("teamCompositionComplaint"))
              return
          }
      } 
      if (needLawyers === true) {
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: true });
      }
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: true });
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: true });

      const generate = async () => {
        if (typeof input !== 'string') {
          dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: false });
          dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });
          dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: false });
          console.error('Input must be a string');
          alert('Input must be a string');
          return;
        }

        // Lawyers set as required by constraints have different flow
        handleRequiredLawyers();

        try {
          const accessToken = await getAccessTokenSilently();
          const newws = authWebSocket(accessToken);
          setWs(newws);

          newws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.source === 'offer_creation_lawyers' && needLawyers === true) {
              handleLawyerGenerationMessage(data);
            } else if (data.source === 'offer_creation_experiences') {
              handleExperienceGenerationMessage(data);
            }
          };

          newws.onopen = () => {
            // This check is for typescript's sake. Check has already been done at start of handleGenerate
            if (constraints && constraints.teamComposition && constraints.teamComposition.length > 0) {
              // Sets the necessary number of lawyer completions to close the websocket
              dispatchOtherData({
                field: 'neededLawyerCompletions',
                value: constraints.teamComposition.length,
              });
              console.log('needed lawyer completions set to', constraints.teamComposition.length);
              for (let item of constraints.teamComposition) {
                if (item.team == 'Any') {
                  newws.send(
                    JSON.stringify({
                      action: 'generateLawyers',
                      query: input.concat('Client Sector: ', sector),
                      language: documentLanguage,
                      team_constraint: '',
                      top_k: item.quantity,
                    })
                  );
                } else {
                  newws.send(
                    JSON.stringify({
                      action: 'generateLawyers',
                      query: input.concat('Client Sector: ', sector),
                      language: documentLanguage,
                      team_constraint: item.team,
                      top_k: item.quantity,
                    })
                  );
                }
              }
            }
            //  else {
            //   newws.send(
            //     JSON.stringify({
            //       action: 'generateLawyers',
            //       query: input,
            //       language: documentLanguage,
            //     })
            //   );
            // }
            newws.send(
              JSON.stringify({
                action: 'generateExperiences',
                query: input,
                language: documentLanguage,
                top_k: constraints?.nExperiences ?? 5,
              })
            );
          };
        } catch (error) {
          console.error('Error establishing WebSocket connection:', error);
          dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_LAWYERS, value: false });
          dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES, value: false });
          dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_LOADING, value: false });
        }
      };
      generate();
    };

    return (
      <div style={styles.section}>
        <div style={styles.flexRow}>
          <Title order={1}>{t('generatedByAI')}</Title>
          <Tooltip label={t('llmAssignedLawyers')} withArrow multiline position="right" w={220}>
            <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
          </Tooltip>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              color={loading ? "orange" : "cyan" }
              variant="light"
              leftSection={loading ? <Loader size="xs" color="cyan" /> : <IconPlus size={18} />}
              style={{ marginRight: '0.5rem' }}
              onClick={() => {
                if (loading) {
                  handleCancel();
                } else {
                  handleGenerate();
                }
              }}
            >
              {loading ? t('cancelGeneration') : t('generateProposal')}
            </Button>
          </div>
        </div>

        {/* Lawyers */}
        <h2 style={{ display: 'flex', alignItems: 'center' }}>
          {t('lawyers')}
          {loadingLawyers && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
              <Loader size="xs" color="blue" />
              <span style={styles.loaderText}>
                {t('loading')} {lawyerLoadingState}
              </span>
            </div>
          )}
        </h2>
        <LawyerTable
          allLawyers={allLawyers}
          selectedLawyers={lawyers}
          dispatchProposal={dispatchProposal}
          //   handleLawyerGenerationMessage={handleLawyerGenerationMessage}
          otherData={otherData}
          proposal={proposal}
        />

        {/* Experience */}
        <h2 style={{ display: 'flex', alignItems: 'center' }}>
          {t('experience')}
          {loadingExperiences && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
              <Loader size="xs" color="blue" />
              <span style={styles.loaderText}>
                {t('loading')} {experienceLoadingState}
              </span>
            </div>
          )}
        </h2>
        <ExperienceTable experiences={experience} dispatchProposal={dispatchProposal} />
      </div>
    );
  }
);

export default AIGeneratedSection;