export const USERFEEDBACKACTIONS = {
  SET_LOADING: 'set_loading',
  SET_LOADING_LAWYERS: 'set_loading_lawyers',
  SET_LOADING_EXPERIENCES: 'set_loading_experiences',
  SET_DOCUMENT_LOADING: 'set_document_loading',
  SET_LAWYER_LOADING_STATE: 'set_lawyer_loading_state',
  SET_EXPERIENCE_LOADING_STATE: 'set_experience_loading_state',
  SET_MODAL: 'set_modal',
  SET_SAVE_LOADING: 'set_save_loading',
};

export const initialUserFeedbackState = {
  loading: false,
  loadingLawyers: false,
  loadingExperiences: false,
  documentLoading: false,
  isModalOpen: false,
  lawyerLoadingState: null as string | null,
  experienceLoadingState: null as string | null,
  saveLoading: false,
};

// Reducer function to handle all state updates
export function userFeedbackReducer(state: typeof initialUserFeedbackState, action: any) {
  switch (action.type) {
    case USERFEEDBACKACTIONS.SET_LOADING:
      return { ...state, loading: action.value };

    case USERFEEDBACKACTIONS.SET_LOADING_LAWYERS:
      return { ...state, loadingLawyers: action.value };

    case USERFEEDBACKACTIONS.SET_LOADING_EXPERIENCES:
      return { ...state, loadingExperiences: action.value };

    case USERFEEDBACKACTIONS.SET_DOCUMENT_LOADING:
      return { ...state, documentLoading: action.value };

    case USERFEEDBACKACTIONS.SET_LAWYER_LOADING_STATE:
      return { ...state, lawyerLoadingState: action.value };

    case USERFEEDBACKACTIONS.SET_EXPERIENCE_LOADING_STATE:
      return { ...state, experienceLoadingState: action.value };

    case USERFEEDBACKACTIONS.SET_MODAL:
      return { ...state, [action.field]: action.value };

    case USERFEEDBACKACTIONS.SET_SAVE_LOADING:
        return { ...state, saveLoading: action.value };

    default:
      return state;
  }
}
