import { Anchor } from '@mantine/core';
import {
  IconKey,
  IconHome,
  IconSwitchHorizontal,
  IconLogout,
  IconLogin,
  IconLabelImportant,
  IconLanguage,
  IconUserEdit,
} from '@tabler/icons-react';
import classes from './NavbarSimple.module.css';
import { NavLink, Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { updateAccountData } from '@/services/account';

export default function NavbarSimple() {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const data = [
    { link: '/proposals', label: t('proposals'), icon: IconHome, clearance: 'auth' },
    { link: '/file-upload', label: t('fileUpload'), icon: IconLabelImportant, clearance: 'auth' }, // TODO: change clearance to admin or something later
    { link: '/editlawyerinfo', label: t('editLawyerInfo'), icon: IconUserEdit, clearance: 'auth' },
  ];

  // Sets language to language user was last on, on the web app
  useEffect(() => {
    if (user && user.user_metadata && user.user_metadata.language) {
      i18n.changeLanguage(user.user_metadata.language);
    }
  }, []);

  const links = data.map((item) => {
    let show = false;
    if (item.clearance == '') {
      show = true;
    } else if (user && item.clearance == 'auth') {
      show = true;
    } else if (
      user &&
      item.clearance == 'admin' &&
      user[import.meta.env.VITE_AUTH0_AUDIENCE + '/roles'].includes('Admin')
    ) {
      show = true;
    }
    if (show) {
      return (
        <NavLink
          className={({ isActive, isPending, isTransitioning }) => {
            const hi = [isActive ? classes.active : '', classes.link].join(' ');
            console.log(isActive);
            console.log(hi);
            return hi;
          }}
          to={item.link}
          key={item.label}
        >
          <item.icon className={classes.linkIcon} stroke={1.5} />
          <span>{item.label}</span>
        </NavLink>
      );
    }
  });

  const handleLanguageSwitch = () => {
    const newLanguage = i18n.language === 'en' ? 'pl' : 'en';
    i18n.changeLanguage(newLanguage);
    async function updateLastLanguage() {
      const accessToken = await getAccessTokenSilently();
      if (user && user.sub) {
        await updateAccountData(accessToken, user.sub, { language: newLanguage });
      }
    }
    updateLastLanguage();
    console.log(`Switched language to ${newLanguage}`);
  };

  return (
    <>
      <div className={classes.navbarMain} id="active">
        {/* <Group className={classes.header} justify="space-between">
          <MantineLogo size={28} />
          <Code fw={700}>v3.1.2</Code>
        </Group> */}
        {links}
      </div>

      <div className={classes.footer}>
        <Anchor
          className={classes.link}
          onClick={(event) => {
            event.preventDefault();
            handleLanguageSwitch();
          }}
        >
          <IconLanguage className={classes.linkIcon} stroke={1.5} />
          <span>{t('switchLanguage')}</span>
        </Anchor>
        <a
          href="#"
          className={classes.link}
          onClick={(event) => {
            event.preventDefault();
            logout({ logoutParams: { returnTo: location.origin } });
          }}
        >
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>{t('logOut')}</span>
        </a>
      </div>
    </>
  );
}
