import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { IconTrash } from '@tabler/icons-react';
import { Modal, Button } from '@mantine/core';
import { removeFile, getFilelist, notifyFileUpload, getPresignedURLs } from '@/services/removeFiles';
import { FileMetadata, PresignedURL, GetPresignedURLsResponse } from '@/interfaces/types';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';

const FileUpload: React.FC = () => {
  const [fileList, setFileList] = useState<string[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [fileToDelete, setFileToDelete] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFileList = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getFilelist(accessToken);
        const sortedFiles = (response.files || []).sort((a: string, b: string) =>
          a.localeCompare(b)
        );
        setFileList(sortedFiles);
        setFilteredFiles(sortedFiles);
      } catch (error) {
        console.error('Error fetching file list:', error);
        alert(t('errorFetchingAlert'));
      }
    };

    fetchFileList();
  }, [getAccessTokenSilently, t]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const duplicateFiles: string[] = [];
        const uniqueFiles: File[] = [];
  
        acceptedFiles.forEach((file) => {
          if (fileList.includes(file.name)) {
            duplicateFiles.push(file.name);
          } else {
            uniqueFiles.push(file);
          }
        });
  
        if (duplicateFiles.length > 0) {
          alert(`The following files are already added and won't be uploaded: ${duplicateFiles.join(',\n ')}`);
        }
  
        if (uniqueFiles.length === 0) {
          alert("No new files to upload.");
          return;
        }
  
        const accessToken = await getAccessTokenSilently();

        // Prepare file metadata for all accepted files
        const filesMetadata = uniqueFiles.map((file) => ({
          filename: file.name,
          filetype: file.type || 'application/octet-stream',
        }));

        // Request pre-signed URLs for all files
        const response: GetPresignedURLsResponse = await getPresignedURLs(accessToken, filesMetadata, "document-database");
        const urls: PresignedURL[] = response.urls; // Now TypeScript knows the type of 'urls'
  
        // Map filenames to their corresponding URLs
        const urlMap = new Map(
          urls.map((item: { filename: string; url: string }) => [item.filename, item.url])
        );

        const successfullyUploadedFiles: string[] = [];
        const unsuccessfullyUploadedFiles: string[] = [];

        // Upload files concurrently using Promise.all
        const uploadPromises = uniqueFiles.map(async (file) => {
          const url = urlMap.get(file.name);
          if (!url) {
            console.error(`No pre-signed URL for file ${file.name}`);
            unsuccessfullyUploadedFiles.push(file.name);
            return;
          }
          try {
            await axios.put(url, file, {
              headers: {
                'Content-Type': file.type || 'application/octet-stream',
              },
            });

            await notifyFileUpload(accessToken, file.name, "add_and_embed_new_file");
            successfullyUploadedFiles.push(file.name);
          } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
            unsuccessfullyUploadedFiles.push(file.name);
          }
        });

        await Promise.all(uploadPromises);

        // Update state and UI with the list of successfully uploaded files
        setFileList((prevList) => {
          const updatedList = [...prevList, ...successfullyUploadedFiles].sort((a, b) =>
            a.localeCompare(b)
          );
          setFilteredFiles(updatedList);
          return updatedList;
        });

        // Provide user feedback
        let message = '';
        if (successfullyUploadedFiles.length > 0) {
          message += `${t('successfulFileUpload')} ${successfullyUploadedFiles.join(',\n')}.\n`;
        }
        if (unsuccessfullyUploadedFiles.length > 0) {
          message += `${t('unsuccessfulFileUpload')} ${unsuccessfullyUploadedFiles.join(',\n')}.`;
        }
        alert(message || t('noFilesUploaded'));
      } catch (error) {
        console.error('Error during file upload:', error);
        alert(t('errorUploadingFiles'));
      }
    },
    [getAccessTokenSilently, t]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'application/pdf': ['.pdf'], // Accept PDF files
      'text/plain': ['.txt'], // Accept text files
      'application/msword': ['.doc'], // Accept .doc files
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], // Accept .docx files
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'], // Accept .pptx files
    },
  });

  const handleDeleteClick = (filename: string) => {
    setFileToDelete(filename);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    const filename = fileToDelete;
    const remove = async () => {
      setFileList((prevList) => {
        const updatedList = prevList
          .filter((file) => file !== fileToDelete)
          .sort((a, b) => a.localeCompare(b));

        const updatedFilteredFiles = updatedList.filter((file) =>
          file.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredFiles(updatedFilteredFiles);

        return updatedList;
      });
      const accessToken = await getAccessTokenSilently();
      await removeFile(accessToken, filename);
    };
    remove();
    setIsModalOpen(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilteredFiles(fileList);
    } else {
      const filtered = fileList.filter((file) =>
        file.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredFiles(filtered);
    }
  };

  return (
    <div>
      <div
        {...getRootProps()}
        style={{ border: '2px dashed #0087F7', padding: '20px', textAlign: 'center' }}
      >
        <input {...getInputProps()} />
        <p>{t('dragDrop')}</p>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3>{t('uploadedFiles')}</h3>
        <div style={{ marginTop: '20px' }}>
          <input
            type="text"
            placeholder={t('searchFiles')}
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
          />
        </div>
        {filteredFiles.length > 0 ? (
          <div
            style={{
              maxHeight: '400px',
              overflowY: 'auto',
              border: '1px solid #ccc',
              padding: '10px',
              width: '100%',
            }}
          >
            <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
              {filteredFiles.map((file) => (
                <li
                  key={file}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px 0',
                    wordWrap: 'break-word',
                    width: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <span style={{ flex: 1, wordBreak: 'break-all' }}>{file}</span>{' '}
                  {/* Ensures long text wraps */}
                  <IconTrash
                    style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                    onClick={() => handleDeleteClick(file)}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>{t('noMatchFiles')}</p>
        )}
      </div>

      {/* Modal for delete confirmation */}
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t('confirmDeletion')}
      >
        <p>
          {t('deleteFileConfirmation')} {fileToDelete}?
        </p>
        <Button color="red" onClick={confirmDelete}>
          {t('confirm')}
        </Button>
        <Button
          variant="outline"
          onClick={() => setIsModalOpen(false)}
          style={{ marginLeft: '10px' }}
        >
          {t('cancel')}
        </Button>
      </Modal>
    </div>
  );
};

export default FileUpload;
