import { authGet, authPost, authPut } from '../../auth/authFetches';

export async function fetchLawyer(accessToken: string, id: string) {
  const path = `/lawyerData/fetchLawyer/${id}`;
  return await authGet(accessToken, path);
}

export async function fetchLawyerImage(accessToken: string, id: string) {
  const path = `/lawyerData/lawyerImage/${id}`;
  const domain = import.meta.env.VITE_API_URL || '';
  const uri = domain + path;
  try {
    const response = await fetch(uri, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      console.error('Failed to fetch image:', response.statusText);
      return null;
    }
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error fetching lawyer image:', error);
    return null;
  }
}

export async function updateImage(accessToken: string, id: string, image: File | null) {
  if (image) {
    const formData = new FormData();
    formData.append('imageFile', image as Blob);
    console.log(formData);
    const path = `/lawyerData/updateImage/${id}`;
    const domain = import.meta.env.VITE_API_URL || '';
    const uri = domain + path;
    try {
      const res = await fetch(uri, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });
      const message = await res.json();
      return message;
    } catch (err) {
      console.error(`Image update request for lawyer with id ${id} failed`);
      console.error(err);
      return;
    }
  } else {
    console.log('No image to update');
  }
}

export async function resummariseLawyer(accessToken: string, lawyerId: string) {
  const path = `/lawyerData/resummarise/${lawyerId}`;
  return await authGet(accessToken, path);
}

export async function deleteLawyerImage(accessToken: string, lawyerId: string) {
  const path = `/lawyerData/deleteImage/${lawyerId}`;
  const domain = import.meta.env.VITE_API_URL || '';
  const uri = domain + path;
  const response = await fetch(uri, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  console.log(response)
  if (!response.ok) {
    throw new Error('Failed to delete image');
  }
};
