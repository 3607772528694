import { IconEdit, IconX, IconPlus } from '@tabler/icons-react';
// import path from 'path';

import {
  Table,
  Anchor,
  Button,
  Modal,
  Center,
  Stack,
  ScrollArea,
  Input,
  Group,
  TextInput,
} from '@mantine/core';
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Lawyer } from '../../interfaces/types';
import { useTranslation } from 'react-i18next';

export default function BasicTable({
  colLabels,
  data,
}: {
  colLabels: { [key: string]: string }; // key is column label as referenced by values, value is the translation key
  data: { [key: string]: string }[]; // keys here are the same as keys above
}) {
  const { t } = useTranslation();
  const colOrder = Object.keys(colLabels);

  return (
    <Stack align="center" w="100%">
      <Table.ScrollContainer minWidth={400}>
        <Table verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              {colOrder.map((key) => (
                <Table.Th key={key}>{t(colLabels[key])}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data && data.map((row) => <BasicRow colOrder={colOrder} row={row} />)}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Stack>
  );
}

function BasicRow({ colOrder, row }: { colOrder: string[]; row: { [key: string]: string } }) {
  const { t } = useTranslation();

  //   const lastEditDate = row.last_edited ? new Date(row.last_edited) : new Date();
  //   const formattedDate = lastEditDate.toISOString().split('T')[0];

  return (
    <Table.Tr key={row[colOrder[0]]}>
      {colOrder.map((key) => (
        <Table.Td key={key}>{row[key]}</Table.Td>
      ))}
      {/* <Table.Td>
          {row.LawyerName}
      </Table.Td>
      <Table.Td>{formattedDate}</Table.Td>
      <Table.Td style={{ textAlign: 'right' }}>
      </Table.Td> */}
    </Table.Tr>
  );
}
