import { styles } from './styles';
import axios from 'axios';
import { Textarea, Button, Loader, Tooltip, NumberInput, Select, MultiSelect, LoadingOverlay } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { PROPOSALACTIONS } from './reducers/proposalReducer';
import { IconPlus, IconInfoCircle } from '@tabler/icons-react';
import { removeFile, getFilelist, notifyFileUpload, getPresignedURLs } from '@/services/removeFiles';
import { Proposal,  FileMetadata, PresignedURL, GetPresignedURLsResponse } from '@/interfaces/types';
import { compact } from 'lodash';
import EditableTable from '../../components/Tables/EditableTable';
import { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchExpertise } from '@/services/proposalsRoutes';

export default function CaseInfoSection({
  proposal,
  dispatchProposal,
  userFeedback,
  dispatchUserFeedback,
  otherData,
  dispatchOtherData,
  reference,
}: {
  proposal: Proposal;
  dispatchProposal: React.Dispatch<any>;
  userFeedback: any;
  dispatchUserFeedback: React.Dispatch<any>;
  otherData: any;
  dispatchOtherData: React.Dispatch<any>;
  reference: any;
}) {
  const { input, sector, constraints } = proposal;
  const { allLawyers, expertise, seniority } = otherData;
  const { loading } = userFeedback;

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [isLLMLoading, setIsLLMLoading] = useState<boolean>(false);


  useEffect(() => {
    async function getExpertise() {
      const accessToken = await getAccessTokenSilently();
      const response = await fetchExpertise(accessToken);
      console.log('Expertise fetch: ', response);
      dispatchOtherData({
        field: 'expertise',
        value: ['Any', ...response],
      });
    }
    getExpertise();
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLLMLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();

        // Prepare file metadata for all accepted files
        const filesMetadata = acceptedFiles.map((file) => ({
          filename: file.name,
          filetype: file.type || 'application/octet-stream',
        }));

        // Request pre-signed URLs for all files
        const response: GetPresignedURLsResponse = await getPresignedURLs(accessToken, filesMetadata, "documents-for-case-info");
        const urls: PresignedURL[] = response.urls; // Now TypeScript knows the type of 'urls'
  
        // Map filenames to their corresponding URLs
        const urlMap = new Map(
          urls.map((item: { filename: string; url: string }) => [item.filename, item.url])
        );

        const successfullyUploadedFiles: string[] = [];
        const unsuccessfullyUploadedFiles: string[] = [];
        const summaries: string[] = [];

        // Upload files concurrently using Promise.all
        const uploadPromises = acceptedFiles.map(async (file) => {
          const url = urlMap.get(file.name);
          if (!url) {
            console.error(`No pre-signed URL for file ${file.name}`);
            unsuccessfullyUploadedFiles.push(file.name);
            return;
          }
          try {
            await axios.put(url, file, {
              headers: {
                'Content-Type': file.type || 'application/octet-stream',
              },
            });

            const response = await notifyFileUpload(accessToken, file.name, "process_case_info_file");
            console.log(response);
            const summary = response.data.summary;
            console.log(summary);
            successfullyUploadedFiles.push(file.name);
            const formattedSummary = `${file.name}:\n${summary}`;
            summaries.push(formattedSummary);
          } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
            unsuccessfullyUploadedFiles.push(file.name);
          }
        });

        await Promise.all(uploadPromises);
        const combinedSummary = summaries.join('\n\n');
        dispatchProposal({
          type: PROPOSALACTIONS.SET_FIELD,
          field: 'input',
          value: proposal.input.length > 0 
          ? proposal.input + '\n\n' + combinedSummary 
          : combinedSummary,
      
        });
  
        // Provide user feedback
        let message = '';
        if (successfullyUploadedFiles.length > 0) {
          message += `${t('successfulFileUpload')} ${successfullyUploadedFiles.join(',\n')}.\n`;
        }
        if (unsuccessfullyUploadedFiles.length > 0) {
          message += `${t('unsuccessfulFileUpload')} ${unsuccessfullyUploadedFiles.join(',\n')}.`;
        }
        alert(message || t('noFilesUploaded'));
      } catch (error) {
        console.error('Error during file upload:', error);
        alert(t('errorUploadingFiles'));
      } finally {
        setIsLLMLoading(false);
      }
    },
    [getAccessTokenSilently, t, proposal.input, dispatchProposal]
  );

  const handleRequiredLawyersChange = (values: string[]) => {
    dispatchProposal({
      type: PROPOSALACTIONS.SET_CONSTRAINTS,
      field: 'requiredLawyers',
      value: values,
    });
  };


  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      'application/pdf': ['.pdf'], // Accept PDF files
      // 'text/plain': ['.txt'], // Accept text files
      'application/msword': ['.doc'], // Accept .doc files
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'], // Accept .docx files
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'], // Accept .pptx files
      'image/jpeg': ['.jpg', '.jpeg'], // Accept JPEG files
      'image/png': ['.png'], // Accept PNG files  
    },
  });

  return (
    <div style={styles.section}>
      <div style={styles.flexRow}>
        <h2>{t('caseInfo')}</h2>
        <Tooltip label={t('provideCaseInfo')} withArrow multiline w={220} position="right">
          <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
      </div>
      <div
        {...getRootProps()}
        style={{ border: '2px dashed #0087F7', padding: '1px', textAlign: 'center' }}
      >
        <input {...getInputProps()} />
        <p>{t('provideContextFile')}</p>
      </div>
      <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={isLLMLoading} zIndex={1} overlayProps={{ radius: "sm", blur: 2 }} />
        <Textarea
          value={input}
          placeholder={t('addYourInputTextHere')}
          onChange={(e) => {
            dispatchProposal({
              type: PROPOSALACTIONS.SET_FIELD,
              field: 'input',
              value: e.target.value,
            });
          }}
          variant="filled"
          autosize
          minRows={6}
        />
      </div>
      <div style={{position: 'relative', width: '30%'}}>
        <h3>{t('clientSector')}</h3>
        <Textarea
          value={sector || ''}
          placeholder={t('clientSectorPlaceholder')}
          onChange={(e) => {
            dispatchProposal({
              type: PROPOSALACTIONS.SET_FIELD,
              field: 'sector',
              value: e.target.value,
            });
          }}
          autosize
          leftSectionWidth={1}
          minRows={1}
        />
      </div>
      <div style={styles.flexRow}>
        <h2>{t('constraints')}</h2>
        <Tooltip label={t('provideConstraints')} withArrow multiline w={220} position="right">
          <IconInfoCircle size={20} color="var(--mantine-primary-color-filled)" />
        </Tooltip>
      </div>
      {/* <NumberInput
        w="20%"
        miw="200px"
        label={t('numberOfLawyers')}
        value={(constraints && constraints.nLawyers) || 3}
        onChange={(value) => {
          dispatchProposal({
            type: PROPOSALACTIONS.SET_CONSTRAINTS,
            field: 'nLawyers',
            value: value,
          });
        }}
      /> */}
      {/* <MultiSelect
        label={t('expertise')}
        data={expertise}
        value={(constraints && constraints.teamComposition) || []}
        onChange={(value) => {
          handleExpertiseChange(value);
        }}
        placeholder={t('selectExpertise')}
      /> */}
      {/* <div style={{ display: 'flex', alignItems: 'end', gap: '0.5rem' }}>
        <h4 style={{ marginTop: '0.8rem', marginBottom: '0' }}>{t('seniority')}</h4>
        <Tooltip label={t('provideSeniority')} withArrow multiline w={220} position="right">
          <IconInfoCircle
            style={{ marginBottom: '0.1rem' }}
            size={20}
            color="var(--mantine-primary-color-filled)"
          />
        </Tooltip>
      </div> */}
      {/* <EditableTable
        rows={constraints?.Seniority || []}
        columns={[
          {
            title: t('seniority'),
            key: 'position',
            type: 'select',
            selectValues: seniority,
            exclusiveSelect: true,
          },
          { title: t('quantity'), key: 'quantity', type: 'number' },
        ]}
        dispatchData={dispatchProposal}
        dispatchAddAction={PROPOSALACTIONS.ADD_SENIORITY_CONSTRAINT}
        dispatchRemoveAction={PROPOSALACTIONS.REMOVE_SENIORITY_CONSTRAINT}
      /> */}
      <MultiSelect
        label={t('requiredLawyers')}
        data={Object.keys(allLawyers)}
        value={(constraints && constraints.requiredLawyers) || []}
        onChange={(values) => {
          handleRequiredLawyersChange(values);
        }}
        placeholder={t('selectRequiredLawyers')}
        searchable
      />
      <div style={{ display: 'flex', alignItems: 'end', gap: '0.5rem' }}>
        <h4 style={{ marginTop: '0.8rem', marginBottom: '0' }}>{t('teamComposition')}</h4>
        <Tooltip label={t('provideTeamComposition')} withArrow multiline w={220} position="right">
          <IconInfoCircle
            style={{ marginBottom: '0.1rem' }}
            size={20}
            color="var(--mantine-primary-color-filled)"
          />
        </Tooltip>
      </div>
      <EditableTable
        rows={constraints?.teamComposition || []}
        columns={[
          {
            title: t('expertise'),
            key: 'team',
            type: 'select',
            selectValues: expertise,
            exclusiveSelect: true,
          },
          {
            title: t('quantity'),
            key: 'quantity',
            type: 'number',
          },
        ]}
        dispatchData={dispatchProposal}
        dispatchAddAction={PROPOSALACTIONS.ADD_TEAM_CONSTRAINT}
        dispatchRemoveAction={PROPOSALACTIONS.REMOVE_TEAM_CONSTRAINT}
      />
      <h4>{t('numberOfExperiences')}</h4>
      <NumberInput 
        min={1} 
        style={{ maxWidth: '25%' }} 
        value={proposal.constraints?.nExperiences ?? 5} 
        onChange={(value) => {
        dispatchProposal({ 
          type: PROPOSALACTIONS.ADD_N_EXPERIENCE_CONSTRAINT, 
          payload: value 
        });
      }} />

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <Button
          color={loading ? "orange" : "cyan" }
          variant="light"
          leftSection={loading ? <Loader size="xs" color="cyan" /> : <IconPlus size={18} />}
          style={{ marginRight: '0.5rem' }}
          onClick={() => {
            if (reference.current) {
              if (loading) {
                reference.current.handleCancel();
              } else {
                reference.current.handleGenerate();
              }
            }
          }}
        >
          {loading ? t('cancelGeneration') : t('generateProposal')}
        </Button>
      </div>

    </div>
  );
}
