import { useCallback, useState, useEffect } from 'react';
import { Button, Modal, Loader } from '@mantine/core';
import { IconCheck, IconPlus, IconX, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce, update } from 'lodash';

import { deleteProposal, generateWordDoc, saveProposal } from '@/services/proposalsRoutes';
import { USERFEEDBACKACTIONS } from './reducers/userFeedbackReducer';

import { Proposal } from '../../interfaces/types';
import { styles } from './styles';
import { FeeOption } from './types';

export default function ActionButtons({
  proposal,
  userFeedback,
  otherData,
  dispatchUserFeedback,
}: {
  proposal: Proposal;
  userFeedback: any;
  otherData: any;
  dispatchUserFeedback: React.Dispatch<any>;
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { documentLoading, isModalOpen, saveLoading } = userFeedback;
  const { proposalId } = location.state as { proposalId: string };
  const { documentLanguage } = otherData;

  const [abortController, setAbortController] = useState<AbortController | null>(null);
  // Handle Save
  const handleSave = useCallback(() => {
    const proposalToSave: Proposal = {
      ...proposal,
      id: proposal.id || proposalId,
      last_edited: new Date(),
      user_metadata: user?.user_metadata,
      language: i18n.language,
    };
    console.log('Saving proposal:', proposalToSave);

    const saveProposalData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        await saveProposal(accessToken, proposalToSave);
        // const newPath = location.pathname.split('/').slice(0, -1).join('/');
        // navigate(newPath);
      } catch (error) {
        console.error('Error saving proposal:', error);
        alert('Failed to save proposal.');
      }
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_SAVE_LOADING, value: false });
    };
    saveProposalData();
  }, [proposal, i18n.language, getAccessTokenSilently, navigate, location.pathname]);

  // Saves proposal after 5 seconds have elapsed since editing
  const debouncedSave = useCallback(debounce(handleSave, 5000), [handleSave]);

  useEffect(() => {
    debouncedSave();
  }, [proposal]);

  useEffect(() => {
    // Handle save on page unload (beware, this only counts for refreshes and tab closes, not for navigation within the SPA - the solution for that is annoying)
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      handleSave();
      // Optional: set a message to confirm leaving the page (depends on UX needs)
      event.preventDefault();
      event.returnValue = ''; // Some browsers require returnValue for the dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      debouncedSave.cancel(); // Cancel any pending debounced save
    };
  }, [handleSave, debouncedSave]);

  const handleCancelGenerate = () => {
    if (abortController) {
      abortController.abort();
      dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_DOCUMENT_LOADING, value: false });
      setAbortController(null);
    }
  };
    // Handle Word Document Generation
  const handleWordDocGenerate = async () => {
    if (documentLoading) return;

    const abortCtrl = new AbortController();
    setAbortController(abortCtrl);  

    const transformFeesForWord = (fees: any) => {
      const activeFee = fees.activeFee;
      const feeOptions: FeeOption[] = ['Lump Sum', 'Retainer', 'Hourly Rate', 'Blended Rate'];
      const optionsToKeys: { [key in FeeOption]: string } = {
        'Lump Sum': 'LumpSum',
        Retainer: 'Retainer',
        'Hourly Rate': 'HourlyRate',
        'Blended Rate': 'BlendedRate',
      };
      const transformedFees = { ...fees };
      feeOptions.forEach((fee) => {
        if (fee !== activeFee) {
          delete transformedFees[optionsToKeys[fee]];
        }
      });
      return transformedFees;
    };

    const proposalForWord: Proposal = {
      ...proposal,
      last_edited: new Date(),
      language: documentLanguage == 'pl' ? 'pl' : 'en',
      fees: transformFeesForWord(proposal.fees),
    };

    try {
      const accessToken = await getAccessTokenSilently();
      await generateWordDoc(accessToken, proposalForWord, (loading: boolean) =>
        dispatchUserFeedback({ type: USERFEEDBACKACTIONS.SET_DOCUMENT_LOADING, value: loading }),
      abortCtrl.signal  
    );
      // Optionally navigate or notify the user
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Document generation aborted');
        // Handle any UI updates if necessary
      } else {
        console.error('Error generating Word document:', error);
        alert('Failed to generate Word document.');
      }
    } finally {
      setAbortController(null); // Reset the controller
    }
  };

  // Handle Delete
  const handleDelete = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await deleteProposal(accessToken, proposalId);
      const newPath = location.pathname.split('/').slice(0, -1).join('/');
      navigate(newPath);
    } catch (error) {
      console.error('Error deleting proposal:', error);
      alert('Failed to delete proposal.');
    }
  };

  // Handle Discard
  const handleDiscard = () => {
    const newPath = location.pathname.split('/').slice(0, -1).join('/');
    navigate(newPath);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginTop: '1rem',
        gap: '12px',
      }}
    >
      <div style={styles.buttonGroup}>
        <Button
          color="green"
          variant="light"
          leftSection={saveLoading ? <Loader size="xs" color="green" />: <IconCheck size={18} />}
          onClick={handleSave}
        >
          {t('save')}
        </Button>
        <Button
          color={documentLoading ? "red" : "blue"}
          variant="light"
          leftSection={documentLoading ? <Loader size="xs" color="blue" /> : <IconPlus size={18} />}
          onClick={documentLoading ? handleCancelGenerate : handleWordDocGenerate}
        >
          {documentLoading ? t('cancel') : t('generateWordDoc')}
        </Button>
        <Button
          color="gray"
          variant="light"
          leftSection={<IconX size={18} />}
          onClick={handleDiscard}
        >
          {t('cancelChanges')}
        </Button>
        <Button
          color="red"
          variant="light"
          leftSection={<IconTrash size={18} />}
          onClick={() =>
            dispatchUserFeedback({
              type: USERFEEDBACKACTIONS.SET_MODAL,
              field: 'isModalOpen',
              value: true,
            })
          }
        >
          {t('deleteProposal')}
        </Button>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        opened={isModalOpen}
        onClose={() =>
          dispatchUserFeedback({
            type: USERFEEDBACKACTIONS.SET_MODAL,
            field: 'isModalOpen',
            value: false,
          })
        }
        title={t('confirmDeletion')}
      >
        <div>{t('confirmDeleteProposal')}</div>
        <div style={styles.buttonGroup}>
          <Button
            color="gray"
            onClick={() =>
              dispatchUserFeedback({
                type: USERFEEDBACKACTIONS.SET_MODAL,
                field: 'isModalOpen',
                value: false,
              })
            }
          >
            {t('cancel')}
          </Button>
          <Button color="red" onClick={handleDelete}>
            {t('confirm')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
